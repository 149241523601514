<template>
  <div
    v-tegulytics-track="getTrackingEvent()"
    :class="classes"
    v-bind="$attrs"
  >
    <komodo-icon
      v-if="icon"
      :color="iconColor"
      :icon="icon"
      :size="IconSize.Small"
    />
    <div class="k2-link-action-label">
      {{ label }}
    </div>
  </div>
</template>

<script lang="ts">
import { computed } from 'vue'

import KomodoIcon from 'komodo2/components/KomodoIcon.vue'

import { IconSize, LinkActionTheme, VueClass } from 'komodo2/types.ts'
import { AnalyticEvent } from 'shared/lib/tegulytics.ts'
import { useTrackable } from 'komodo2/composables/trackable.ts'

export default {
  name: 'KomodoLinkAction'
}
</script>

<script setup lang="ts">
interface Props {
  /**
   * The icon that should show next to the label
   */
  icon?: string
  /**
   * The icon color override name.
   */
  iconColor?: string
  /**
   * The text to display as the label
   */
  label: string
  /**
   * Theme to be applied to label text
   */
  theme?: LinkActionTheme
  /**
   * Whether or not the LinkAction should render in a disabled state and not react to events
   */
  disabled?: boolean
  /**
   * The base identifier or event payload to use for any analytic events triggered by this component
   */
  trackingEvent?: string | AnalyticEvent
  /**
   * The string to use when joining subidentifers together. Avoid using this, its purpose was to handle some backwards compatibility.
   */
  trackingIdentifierGlue?: string
}

const props = withDefaults(defineProps<Props>(), {
  icon: undefined,
  iconColor: undefined,
  disabled: false,
  theme: LinkActionTheme.Default,
  trackingEvent: undefined,
  trackingIdentifierGlue: undefined
})

const { getTrackingEvent } = useTrackable(props)

const classes = computed((): VueClass => {
  return {
    'k2-link-action': true,
    'k2-disabled': props.disabled,
    'k2-underlined': props.theme === LinkActionTheme.Underlined
  }
})

const iconColor = computed((): string => {
  switch (true) {
    case props.disabled:
      return 'text_disabled_text'
    case !!props.iconColor:
      return props.iconColor
    default:
      return 'main_tegus_blue'
  }
})

</script>

<style lang="scss" scoped>
@import '../scss/common';

.k2-link-action {
  display: inline-flex;
  align-items: center;
  gap: 4px;

  .k2-link-action-label {
    @include k2-font-button-button-small;
  }

  &:hover, &:active {
    cursor: pointer;
  }

  &:focus:not(.k2-disabled) {
    @include k2-focus-outline;

    border-radius: 4px;
  }

  &.k2-disabled {
    @include k2-color-text-disabled-text;

    pointer-events: none;
  }

  &.k2-underlined {
    .k2-link-action-label {
      @include k2-font-text-caption;
      @include k2-color-primary-darken-5;

      text-decoration: underline;
    }
  }
}

.k2-link-action:not(.k2-disabled) {
  .k2-link-action-label {
    @include k2-color-main-tegus-blue;
  }

  &:hover {
    .k2-link-action-label {
      @include k2-color-main-tegus-blue-hover;
    }

    .k2-icon {
      // To forcefully override the main color upon hover/active
      color: $k2-main-tegus-blue-hover !important;
    }
  }

  &:active {
    .k2-link-action-label {
      @include k2-color-main-tegus-blue-pressed;
    }

    .k2-icon {
      // To forcefully override the main color upon hover/active
      color: $k2-main-tegus-blue-pressed !important;
    }
  }
}
</style>
